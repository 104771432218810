.green {
    color: #73bf43;
}

.grey {
    color: #c9c9c9;
}

.opt-in {
    form {
        margin-bottom: 4em;

        .selectize-dropdown-content {
            max-height: 210px;
        }

        .shirt-options {
            .selectize-dropdown-content {
                max-height: 130px;
            }
        }
    }
}

.donate-page {
  .btn-continue {
    background-color: #fff;
  }

  .btn-green {
    background-color: #73bf43;
    color: #fff;
  }

  .btn-gray:hover {
    background-color: #c9c9c9;
  }

  @media screen and (max-width: 990px) {
    .btn-continue {
      position: fixed;
      width: 70%;
      bottom: 0;
      left: 15%;
    }
  }
}

.donate-login {
  p, button {
    width: 100%;
  }
}

.donate-guest-wrapper {
  clear: both;
  padding-top: 1.5em;
}

@media screen and (min-width: 1024px) {
  .donate-login {
    p, button {
      width: auto;
    }
  }

  .donate-guest-wrapper {
    clear: none;
    padding-top: 0;

    p, button {
      float: right;
    }
  }
}
