.green {
  color: #73bf43;
}

.grey {
  color: #c9c9c9;
}

.opt-in form {
  margin-bottom: 4em;
}

.opt-in form .selectize-dropdown-content {
  max-height: 210px;
}

.opt-in form .shirt-options .selectize-dropdown-content {
  max-height: 130px;
}

.donate-page .btn-continue {
  background-color: #fff;
}

.donate-page .btn-green {
  background-color: #73bf43;
  color: #fff;
}

.donate-page .btn-gray:hover {
  background-color: #c9c9c9;
}

@media screen and (max-width: 990px) {
  .donate-page .btn-continue {
    position: fixed;
    width: 70%;
    bottom: 0;
    left: 15%;
  }
}

.donate-login p, .donate-login button {
  width: 100%;
}

.donate-guest-wrapper {
  clear: both;
  padding-top: 1.5em;
}

@media screen and (min-width: 1024px) {
  .donate-login p, .donate-login button {
    width: auto;
  }
  .donate-guest-wrapper {
    clear: none;
    padding-top: 0;
  }
  .donate-guest-wrapper p, .donate-guest-wrapper button {
    float: right;
  }
}
